import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import {
  DEPOSIT_DETAILS_KEY,
  DEPOSIT_ID_KEY,
  TOKEN_KEY
} from '../../config/constants/storage-key-constants';
import { DepositService } from '../deposit/deposit.service';
import { InMemoryStorageService } from '../storage/in-memory-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private inMemoryStorageService: InMemoryStorageService,
              private depositService: DepositService) { }

  authenticate(depositId: string, jwtToken: string): Observable<boolean> {
    this.inMemoryStorageService.setItem(DEPOSIT_ID_KEY, depositId);
    this.inMemoryStorageService.setItem(TOKEN_KEY, jwtToken);

    return this.depositService.getDepositDetails()
      .pipe(
        map(depositDetails => {
          if (depositDetails !== null && depositDetails !== undefined) {
            this.inMemoryStorageService.setItem(DEPOSIT_DETAILS_KEY, depositDetails);
            return true;
          }

          return false;
        }),
        catchError(error => {
          console.error(error);
          return of(false); // Return an observable emitting 'false'
        })
      );
  }

  isAuthenticated(): Observable<boolean> {
    return of(
      this.inMemoryStorageService.hasItem(TOKEN_KEY) && this.inMemoryStorageService.hasItem(DEPOSIT_DETAILS_KEY)
    );
  }
}
