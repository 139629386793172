import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';

import { environment } from '../../../environments/environment';
import { DEPOSIT_ID_KEY, TOKEN_KEY } from '../../config/constants/storage-key-constants';
import { CardBrand } from '../../models/enums/card-brand';
import { InstrumentType } from '../../models/enums/instrument-type';
import { PaymentOption } from '../../models/enums/payment-option';
import { ProcessDepositRequest } from '../../models/request/process-deposit-request';
import { DepositDetails } from '../../models/response/deposit-details';
import { DepositOption } from '../../models/response/deposit-option';
import { CardSchemeService } from '../card-scheme/card-scheme.service';
import { NotRecoverableError } from '../error/not-recoverable-error';
import { InMemoryStorageService } from '../storage/in-memory-storage.service';

const depositApiUrl: string = '/digitalwallets/v1/profile/deposits';

@Injectable({
  providedIn: 'root'
})
export class DepositService {

  supportedCardBrands: CardBrand[];

  constructor(private inMemoryStorageService: InMemoryStorageService,
              private mapperService: CardSchemeService,
              private httpClient: HttpClient) {}

  getDepositDetails(): Observable<DepositDetails> {
    const depositId = this.inMemoryStorageService.getItem(DEPOSIT_ID_KEY);
    const httpOptions = this.createHttpOptions();

    return this.httpClient.get<DepositDetails>(this.getDepositEndpoint(depositId), httpOptions);
  }

  processDeposit(body: ProcessDepositRequest): Observable<DepositDetails> {
    const depositId = this.inMemoryStorageService.getItem(DEPOSIT_ID_KEY);
    const httpOptions = this.createHttpOptions();

    return this.httpClient.post<DepositDetails>(this.processDepositEndpoint(depositId), body, httpOptions);
  }

  initializeDepositOptions(): void {
    const httpOptions = this.createHttpOptions();

    this.httpClient.get<DepositOption[]>(this.getDepositOptionsEndpoint(), httpOptions).pipe(
      tap(depositOptions => {
        const cardDepositOption = depositOptions.find(option => option.paymentOption === PaymentOption.CARD
          && option.instrumentTypes.includes(InstrumentType.CARD));

        if (cardDepositOption?.paymentOptionSettings?.cardSchemes) {
          this.supportedCardBrands = cardDepositOption.paymentOptionSettings.cardSchemes
            .map(this.mapperService.mapPaysafeCardSchemeToCardBrand);
        } else {
          throw new NotRecoverableError('No supported card schemes');
        }
      })
    ).subscribe();
  }

  getSupportedCardBrands(): CardBrand[] {
    return this.supportedCardBrands;
  }

  private getDepositEndpoint(depositId): string {
    return `${environment.BACKEND_URL}${depositApiUrl}/${depositId}`;
  }

  private getDepositOptionsEndpoint(): string {
    return `${environment.BACKEND_URL}${depositApiUrl}/options`;
  }

  private processDepositEndpoint(depositId): string {
    return `${environment.BACKEND_URL}${depositApiUrl}/${depositId}/status`;
  }

  private createHttpOptions(): { headers: HttpHeaders } {
    const jwt = this.inMemoryStorageService.getItem(TOKEN_KEY);
    return {
      headers: new HttpHeaders({
        Authorization: `Bearer ${jwt}`
      })
    };
  }
}
