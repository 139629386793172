import { provideHttpClient } from '@angular/common/http';
import { enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { provideEnvironmentNgxMask, provideNgxMask } from 'ngx-mask';

import { AppComponent } from './app/app.component';
import routes from './app/app.routes';
import { GlobalErrorHandler } from './app/services/error/handler/global-error-handler';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
      importProvidersFrom(RouterModule.forRoot(routes)),
      provideHttpClient(),
      provideEnvironmentNgxMask(),
      provideNgxMask(),
      provideAnimations(),
      {
        provide: ErrorHandler,
        useClass: GlobalErrorHandler
      }
    ]
})
  .catch(err => console.error(err));
