import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';

import { DW_DUPLICATE_CARD, DW_EXCEEDED_MAX_NUM_OF_CARDS } from '../../../errors/messages/deposit-error-messages';
import { DUPLICATE_CARD_ERROR, MAX_NUM_CARDS_ERROR } from '../../../errors/messages/user-friendly-error-messages';
import { UrlType } from '../../../models/enums/url-type';
import { DepositStateService } from '../../deposit/deposit-state.service';
import { RedirectUrlService } from '../../redirect-url/redirect-url.service';
import { NotRecoverableError } from '../not-recoverable-error';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private redirectUrlService: RedirectUrlService,
              private depositStateService: DepositStateService) { }

  handleError(error: any): void {
    if (error instanceof HttpErrorResponse) {
      this.handleHttpErrorResponse(error);
    } else if (error instanceof NotRecoverableError) {
      this.redirectUrlService.returnBackToMerchant(UrlType.ON_FAILED);
    } else {
      console.error(error);
    }
  }

  private handleHttpErrorResponse(error: HttpErrorResponse): void {
    const code = error.error?.error?.code;

    if (code === undefined) {
      this.redirectUrlService.returnBackToMerchant(UrlType.ON_FAILED);
    }

    switch (code) {
      case DW_EXCEEDED_MAX_NUM_OF_CARDS:
        this.updateFailedDepositDetails(MAX_NUM_CARDS_ERROR);
        break;
      case DW_DUPLICATE_CARD:
        this.updateFailedDepositDetails(DUPLICATE_CARD_ERROR);
        break;
      default:
        this.redirectUrlService.returnBackToMerchant(UrlType.ON_FAILED);
    }
  }

  private updateFailedDepositDetails(message: string): void {
    this.depositStateService.updateDepositState({
      isDepositFailed: true,
      failedDepositErrorMessage: message
    });
  }
}
