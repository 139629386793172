import { Routes } from '@angular/router';

import { authGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/login/login.component')
        .then(mod => mod.LoginComponent)
  },
  {
    path: 'cdtl',
    canActivate: [authGuard],
    loadComponent: () =>
      import('./components/card-details/card-details.component')
        .then(mod => mod.CardDetailsComponent)
  },
  {
    path: 'cdtl/preview',
    canActivate: [authGuard],
    loadComponent: () =>
      import('./components/preview/preview.component')
        .then(mod => mod.PreviewComponent)
  },
  {
    path: 'unauthorized',
    loadComponent: () =>
      import('./components/unauthorized/unauthorized.component')
        .then(mod => mod.UnauthorizedComponent)
  },
  {
    path: '**',
    redirectTo: '/unauthorized'
  }
];

export default routes;
