import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { DepositState } from '../../models/common/deposit-state';

@Injectable({
  providedIn: 'root'
})
export class DepositStateService {

  private depositStateSubject: BehaviorSubject<DepositState> = new BehaviorSubject<DepositState>({
    isDepositFailed: false,
    failedDepositErrorMessage: ''
  });
  depositState$: Observable<DepositState> = this.depositStateSubject.asObservable();

  updateDepositState(state: DepositState): void {
    this.depositStateSubject.next(state);
  }
}
