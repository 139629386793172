export enum CardBrand {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  AMEX = 'AMEX',
  MAESTRO = 'MAESTRO',
  CARTEBLUE = 'CARTEBLEUE',
  DINERS = 'DINERS',
  JCB = 'JCB',
  DISCOVER = 'DISCOVER'
}
