import { Injectable } from '@angular/core';

import { DEPOSIT_DETAILS_KEY } from '../../config/constants/storage-key-constants';
import { UrlType } from '../../models/enums/url-type';
import { DepositDetails } from '../../models/response/deposit-details';
import { InMemoryStorageService } from '../storage/in-memory-storage.service';

/**
 * This service is introduced to handle all redirections to external resources
 * and handle logic around merchant redirection per brand, putting additional
 * query parameters when needed, etc.
 */
@Injectable({
  providedIn: 'root'
})
export class RedirectUrlService {

  constructor(private inMemoryStorageService: InMemoryStorageService) { }

  redirectToUrl(redirectUrl: string): void {
    if (!redirectUrl) {
      return;
    }

    window.location.href = redirectUrl;
  }

  returnBackToMerchant(urlType: UrlType): void {
    const depositDetails: DepositDetails = this.inMemoryStorageService.getItem(DEPOSIT_DETAILS_KEY);

    const returnUrl = depositDetails.returnUrls.find(r => r.rel === urlType)
      || depositDetails.returnUrls.find(r => r.rel === UrlType.DEFAULT);
    this.redirectToUrl(returnUrl.href);
  }
}
