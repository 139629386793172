import { Injectable } from '@angular/core';

import { CardBrand } from '../../models/enums/card-brand';
import { PaysafeCardScheme } from '../../models/enums/paysafe-card-scheme';

@Injectable({
  providedIn: 'root'
})
export class CardSchemeService {

  constructor() { }

  mapPaysafeCardSchemeToCardBrand(cardScheme: PaysafeCardScheme): CardBrand {
    switch (cardScheme) {
      case PaysafeCardScheme.VISA: return CardBrand.VISA;
      case PaysafeCardScheme.MC: return CardBrand.MASTERCARD;
      default: return undefined;
    }
  }
}
