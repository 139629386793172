import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InMemoryStorageService {

  private storage: { [key: string]: any } = {};

  constructor() {}

  // Get item from storage
  getItem<T>(key: string): T | undefined {
    const item = this.storage[key];
    return item !== undefined ? (item as T) : undefined;
  }

  hasItem(key: string): boolean {
    return this.storage[key] !== undefined;
  }

  // Set item in storage
  setItem<T>(key: string, value: T): void {
    this.storage[key] = value;
  }

  // Remove item from storage
  removeItem(key: string): void {
    delete this.storage[key];
  }

  // Clear all items from storage
  clear(): void {
    this.storage = {};
  }
}
